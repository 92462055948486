import classes from "./homePage.module.css";
import iconIg from "../assets_ojas/instagram.svg";
import iconFb from "../assets_ojas/facebook.svg";
import iconYt from "../assets_ojas/youtube.svg";
import dateImg from "../assets/date.webp";
import heroAdvImg from "../assets_ojas/stage.jpg";
import sponsorsImg from "../assets_pack/sponsors.webp";
import ojas_np from "../assets/ojas_np.png";
import greenovate from "../assets/greenovate.jpg";
import mocknitiayog from "../assets/mocknitiayog.jpg";
import caserelay from "../assets/caserelay.jpg";
import finvisor from "../assets/finvisor.jpg";
import operace from "../assets/operace.jpg";
import makeorbreak from "../assets/makeorbreak.jpg";
import sahyog from "../assets/sahyog.jpg";
import cesimelite from "../assets/cesimelite.jpg";
import olympus from "../assets/olympus.jpg";
import spellbound from "../assets/spellbound.jpg";
import aihorizon from "../assets/aihorizon.jpg";
import advertere from "../assets/advertere.jpg";
import techtonicshift from "../assets/techtonicshift.jpg";
import aspire from "../assets/aspire.jpg";
import consultomania from "../assets/consultomania.jpg";
import findingneo from "../assets/findingneo.jpg";
import unscripted from "../assets/unscripted.jpg";
import brando from "../assets/brando.jpg";
import xfactor from "../assets/xfactor.jpg";

import s_biswas from "../assets/s-biswas.webp";
import s_csm from "../assets/s-csm.webp";
import s_edevlop from "../assets/s-edevlop.webp";
import s_jyotemotors from "../assets/s-jyotemotors.webp";
import s_navbharat from "../assets/s-navbharat.webp";
import s_ocac from "../assets/s-ocac.webp";
import s_omc from "../assets/s-omc.webp";
import s_pantaloons from "../assets/s-pantaloons.webp";
import s_printlink from "../assets/s-printlink.webp";
import s_rungta from "../assets/s-rungta.webp";
import s_wizertech from "../assets/s-wizertech.webp";
import s_dell from "../assets/s-dell.webp";
import s_hp from "../assets/s-hp.webp";
import s_insidefpv from "../assets/s-insidefpv.webp";

import s_axisbank from "../assets/s_axisbank.webp";
import s_businessstandard from"../assets/s_businessstandard.webp";
import s_angelone from "../assets/s_angeone.webp";

import e0thumb from "../assets/e0thumb.webp";
import e1thumb from "../assets/e1thumb.webp";
import e2thumb from "../assets/e2thumb.webp";
import e3thumb from "../assets/e3thumb.webp";
import e4thumb from "../assets/e4thumb.webp";
import e5thumb from "../assets/e5thumb.webp";
import e6thumb from "../assets/e6thumb.webp";
import e7thumb from "../assets/e7thumb.webp";

import pn_ak from "../assets/pn_ak.webp";
import pn_ss from "../assets/pn_ss.webp";
import pn_dc from "../assets/pn_dc.webp";
import pn_bp from "../assets/pn_bp.webp";

import { useLottie } from "lottie-react";
import logoAnim from "../assets/logoanim.json";

import AngleButton from "../components/angleButton";
import PopBox from "../components/popbox";

import { motion, useInView } from "framer-motion";
import { useRef } from "react";
import CountUp from 'react-countup';
import { useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
    Navigation,
    Pagination,
    Autoplay,
    Virtual
} from "swiper/core";
import "swiper/swiper-bundle.css";
import "./swiperCustom.css";
import { useMediaQuery } from 'react-responsive';
import { HashLink } from "react-router-hash-link";

SwiperCore.use([Navigation, Pagination, Autoplay, Virtual]);

let swiperCardCount = 0;
function redirect ()
{
    window.location.href="https://unstop.com/college-fests/ojas-xvi-sp-jain-institute-of-management-and-research-spjimr-mumbai-174210";
}
function swiperCard(img, linkto, title, subtitle) {
    return (
        <SwiperSlide key={`slide-${swiperCardCount}`} style={{ listStyle: "none" }}>
            <HashLink style={{ textDecoration: 'inherit', color: 'inherit' }} to={linkto}>
                <div className={classes.sliderCard}>
                    <img src={img} alt="slider" />
                    <div className={classes.sliderCardOverlay} />
                    <div className={classes.sliderCardText}>
                        <div>{subtitle}</div>
                        <div><span className={classes.sliderTextLine} />{title}</div>
                    </div>
                </div>
            </HashLink>
        </SwiperSlide>
    );
}

function proNightsCard(img, details, title, subtitle, desc) {
    return (
        <div className={classes.sliderCard + " " + classes.proCard}>
            <img src={img} alt="slider" />
            <div className={classes.sliderCardOverlay} />
            <div className={classes.sliderCardOverlay + " " + classes.proCardOverlay} />
            <div className={classes.sliderCardText}>
                <div><span>{subtitle}</span><br /><b style={{ color: "white" }}>{details}</b></div>
                <div><span className={classes.sliderTextLine} />{title}</div>
            </div>
            <div className={classes.proCardDesc}>{desc}</div>
        </div>
    );
}

function sponsorCard(img, link) {
    return (
        <div className={classes.sponsorCardWrap}>
            <a rel="noreferrer" target="_blank" href={link}>
                <div className={classes.sponsorCard}>
                    <img src={img} alt="sponsor" />
                    <div className={classes.sponsorCardOverlay} />
                </div>
            </a>
        </div>
    );
}

let sponsorData1 = [{ img: s_ocac, link: "https://www.ocac.in" }];
let sponsorData2 = [{ img: s_omc, link: "https://omcltd.in" }];
let sponsorData3 = [
    { img: s_biswas},
    { img: s_csm },
    { img: s_edevlop },
    { img: s_jyotemotors},
    { img: s_navbharat},
    { img: s_pantaloons},
    { img: s_printlink},
    { img: s_rungta},
    { img: s_wizertech},
    { img: s_hp},
    { img: s_dell},

    { img: s_axisbank},
    { img: s_angelone},
    { img: s_businessstandard},
];
let sponsorData4 = [{ img: s_insidefpv, link: "https://insidefpv.com" }];

function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
}

shuffleArray(sponsorData1);
shuffleArray(sponsorData2);
shuffleArray(sponsorData3);
shuffleArray(sponsorData4);

export default function HomePage() {
    const options = {
        animationData: logoAnim,
        loop: true
    };
    const { View } = useLottie(options);

    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });
    const slides1 = useMediaQuery({ query: '(max-width:1050px)' });

    const [contactBtnText, setcontactBtnText] = useState("SEND MESSAGE");
    const [popUp, setPopUp] = useState(false)

    // const sendMessage = async (e) => {
    //     e.preventDefault();
    //     setcontactBtnText("SENDING...")
    //     const data = { subject: e.target[0].value, message: e.target[1].value, name: e.target[2].value, email: e.target[3].value };
    //     const settings = {
    //         method: 'POST',
    //         headers: {
    //             Accept: 'application/json',
    //             'Content-Type': 'application/json',
    //         },
    //         body: JSON.stringify(data)
    //     };
    //     try {
    //         const res = await fetch("", settings);
    //         setcontactBtnText(res.status === 200 ? "MESSAGE SENT ✅" : "ERROR ⛔");
    //     }
    //     catch (e) {
    //         setcontactBtnText("ERROR ⛔")
    //     }
    // }

    return (
        <div className={classes.homePage}>
            <PopBox setPopUp={setPopUp} popUp={popUp} />
            <section className={classes.hero}>
                <div className={classes.title}><img src={ojas_np} style={{width:'40vw',height:'45vh'}}/></div>
                <div className={classes.leftVertical}>
                    <div>FOLLOW US ON: </div>
                    <a rel="noreferrer" target="_blank" href="https://www.instagram.com/spjimr.ojas"><img src={iconIg} alt="social" /></a>
                    <a rel="noreferrer" target="_blank" href="https://www.facebook.com/spjimr.ojas"><img src={iconFb} alt="social" /></a>
                    <a rel="noreferrer" target="_blank" href="https://youtube.com/@SPJIMRMumbai_Official?si=uijVy526YFiRd4S-"><img src={iconYt} alt="social" /></a>
                </div>

                <div className={classes.rightVertical}>
                    OJAS XVI, SPJIMR MUMBAI
                </div>

                {/* <div className={classes.lottieAnim}>{View}</div> */}
                {/* <motion.div viewport={{ once: true }} initial={{ transform: 'translateY(-10rem) scale(0)', opacity: 0 }} whileInView={{ transform: 'translateY(0rem) scale(1)', opacity: 1 }} transition={{ duration: 2, type: "spring" }} className={classes.dateHero}><img src={dateImg} alt="3 to 5 March" /></motion.div> */}
            </section>

            <section className={classes.aboutSection}>
                <motion.div viewport={{ once: true }} initial={{ transform: 'translateY(6rem)', opacity: 0 }} whileInView={{ transform: 'translateY(0rem)', opacity: 1 }} transition={{ duration: 2, type: "spring", delay: 0.15 }}>
                    <section >
                        <div className={classes.sliderWrapper}>
                            <div className={classes.slider} >
                                <img id="slide-1" src={greenovate} alt="Event"/>
                                <img id="slide-2" src={mocknitiayog} alt="Event" />
                                <img id="slide-3" src={finvisor} alt="Event" />
                                 <img id="slide-4" src={makeorbreak} alt="Event" />
                                 <img id="slide-5" src={caserelay} alt="Event" />
                                 <img id="slide-6" src={operace} alt="Event" />
                                 <img id="slide-7" src={sahyog} alt="Event"/>
                                <img id="slide-8" src={techtonicshift} alt="Event" />
                                <img id="slide-9" src={aspire} alt="Event" />
                                 <img id="slide-10" src={aihorizon} alt="Event" />
                                 <img id="slide-11" src={advertere} alt="Event" />
                                 <img id="slide-12" src={cesimelite} alt="Event" />
                                 <img id="slide-13" src={olympus} alt="Event" />
                                 <img id="slide-14" src={spellbound} alt="Event" />
                                 <img id="slide-15" src={brando} alt="Event" />
                                 <img id="slide-16" src={consultomania} alt="Event" />
                                 <img id="slide-17" src={unscripted} alt="Event" />
                                 <img id="slide-18" src={xfactor} alt="Event" />
                                 <img id="slide-19" src={findingneo} alt="Event" />
                            </div>
                            <div className={classes.sliderNav}>
                                <a href="#slide-1"></a>
                                <a href="#slide-2"></a>
                                <a href="#slide-3"></a>
                            </div>
                        </div>
                    </section>
                </motion.div>
                <div className={classes.aboutText}>
                    <div>
                        <div>2024</div>
                        <motion.div viewport={{ once: true }} initial={{ transform: 'translateX(-10rem)', opacity: 0 }} whileInView={{ transform: 'translateX(-3rem)', opacity: 1 }} transition={{ duration: 2, type: "spring" }}>
                            <div>OJAS XVI</div>
                            <div>ODYSSEY TO INFINITY</div>
                        </motion.div>
                    </div>
                    <div>Lorem ipsum dolor sit amet. Ut quia natus eos sunt ducimus qui quaerat saepe. Eos placeat aliquam eos laudantium iste ut consequatur itaque. Sit nihil sapiente qui maxime nesciunt vel commodi sequi eos quae voluptas At quos internos eos ullam adipisci.</div>
                    <div className={classes.headerBtns}>
                        <motion.div viewport={{ once: true }} initial={{ transform: 'translateX(-6rem)', opacity: 0 }} whileInView={{ transform: 'translateX(-0.5rem)', opacity: 1 }} transition={{ duration: 2, type: "spring", delay: 0.15 }}><a rel="noreferrer" target="_blank" href={"https://unstop.com/college-fests/ojas-xvi-sp-jain-institute-of-management-and-research-spjimr-mumbai-174210"}><AngleButton text="EVENTS SCHEDULE"/></a></motion.div>
                        <motion.div viewport={{ once: true }} initial={{ transform: 'translateX(6rem)', opacity: 0 }} whileInView={{ transform: 'translateX(-0.5rem)', opacity: 1 }} transition={{ duration: 2, type: "spring", delay: 0.15 }} onClick={() => setPopUp(true)}><AngleButton text="REGISTER" /></motion.div>
                    </div>
                </div>
            </section >

            <section className={classes.countSection} ref={ref}>
                <div className={classes.countWrap}  >
                    <div>{isInView ? <CountUp end={31} duration={1} /> : null}K+</div>
                    <div>REGISTRATIONS</div>
                </div>
                <div className={classes.countWrap}>
                    <div>{isInView ? <CountUp end={1200} duration={0.3} delay={0.2} /> : null}+</div>
                    <div>PARTICIPATING<br />COLLEGES</div>
                </div>
                <div className={classes.countWrap}>
                    <div>{isInView ? <CountUp end={40} duration={1} delay={0.4} /> : null}+</div>
                    <div>EVENTS</div>
                </div>
                <div className={classes.countWrap}>
                    <div>{isInView ? <CountUp end={533} duration={1} delay={0.6} /> : null}K+</div>
                    <div>REACH ACROSS<br />SOCIAL MEDIA</div>
                </div>
            </section>

            <section className={classes.videosection}>
                <motion.div viewport={{ once: true }} initial={{ transform: 'translateX(6rem)', opacity: 0 }} whileInView={{ transform: 'translateX(0rem)', opacity: 1 }} transition={{ duration: 1.5, type: "spring" }} className={classes.videosecTitle}>
                    <div>A GLIMPSE INTO<br />ODYSSEY TO INFINITY</div>
                    <div>#OJASXVI</div>
                </motion.div>
                <a rel="noreferrer" target="_blank" href="https://www.youtube.com/watch?v=1EljRQ4PyFc">
                    <div className={classes.ytvideo}>
                        <div />
                        <div>
                            <img src={iconYt} alt="youtube" />
                        </div>
                    </div>
                </a>
                {/* <div className={classes.swiperWrap}>
                    <Swiper
                        className={classes.swiper}
                        id="swiper"
                        slidesPerView={slides1 ? 1 : 2}
                        spaceBetween={0}
                        autoplay={{
                            delay: 4000,
                            disableOnInteraction: false,
                        }}
                        pagination={{
                            clickable: true,
                        }}
                        loop
                    >
                        {swiperCard(e0thumb, "/#pronights", "PRONIGHTS", "Unleash the fun at the concerts of some of India's finest musicians, comedians, and DJs")}
                        {swiperCard(e1thumb, "/events#technical_events", "TECHNICAL EVENTS", "Unravel the tech giant within you")}
                        {swiperCard(e2thumb, "/events#cultural_events", "CULTURAL EVENTS", "Enchant the arena with your charming voice or set the stage on fire with your dazzling steps")}
                        {swiperCard(e3thumb, "/events#litfest_events", "FILM AND THEATER", "Get ready to bring out your inner 'Kalakar'")}
                        {swiperCard(e4thumb, "/events#art_events", "ART AND E-SPORTS", "They say art lies all around us, also hola gamers!! may the best say GG")}
                        {swiperCard(e5thumb, "/events#photography_events", "PHOTOGRAPHY", "Capture the world full of possibilities")}
                        {swiperCard(e6thumb, "/events#food_events", "FOOD AND CSR", "Enjoy the amazing flavours and feed your soul with exotic delicacies and good deeds")}
                        {swiperCard(e7thumb, "/events#social_connect", "SOCIAL CONNECT", "Help support important causes and make a positive impact in the community")}
                    </Swiper>
                </div> */}
            </section>

            {/*<section className={classes.sponsorsSection} id="sponsors">
                <motion.div viewport={{ once: true }} initial={{ transform: 'translateY(6rem)', opacity: 0 }} whileInView={{ transform: 'translateY(0rem)', opacity: 1 }} transition={{ duration: 1.5, type: "spring" }}><img src={sponsorsImg} alt="Sponsors" /></motion.div>
                <div className={classes.sponsorTitle}><span style={{ color: 'white' }}>TITLE</span><br /> SPONSOR</div>
                <div className={classes.sponsorsWrap + " " + classes.fullWidth}>
                    {sponsorData1.map(e => sponsorCard(e.img, e.link))}
                </div>
                <div className={classes.sponsorTitle}><span style={{ color: 'white' }}>CO-TITLE</span><br /> SPONSOR</div>
                <div className={classes.sponsorsWrap + " " + classes.fullWidth}>
                    {sponsorData2.map(e => sponsorCard(e.img, e.link))}
                </div>
                <div className={classes.sponsorTitle}><span style={{ color: 'white' }}>ASSOCIATE</span><br /> SPONSORS</div>
                <div className={classes.sponsorsWrap}>
                    {sponsorData3.map(e => sponsorCard(e.img, e.link))}
                </div>
                <div className={classes.sponsorTitle}><span style={{ color: 'white' }}>DRONE</span><br /> PARTNER</div>
                <div className={classes.sponsorsWrap}>
                    {sponsorData4.map(e => sponsorCard(e.img, e.link))}
                </div>
                <motion.div viewport={{ once: true }} initial={{ transform: 'translateY(6rem)', opacity: 0 }} whileInView={{ transform: 'translateY(0rem)', opacity: 1 }} transition={{ duration: 1.5, type: "spring" }}><HashLink to="/sponsors#"><AngleButton text="SPONSOR US" /></HashLink></motion.div>
            </section>*/}

            {/*<section className={classes.proNights} id="pronights">
                <div className={classes.proNightsTitle}>
                    <motion.div viewport={{ once: true }} initial={{ transform: 'translateX(6rem)', opacity: 0 }} whileInView={{ transform: 'translateX(0rem)', opacity: 1 }} transition={{ duration: 1.5, type: "spring" }}>PRONITES</motion.div>
                    <motion.div viewport={{ once: true }} initial={{ transform: 'translateY(3rem)', opacity: 0 }} whileInView={{ transform: 'translateY(0rem)', opacity: 1 }} transition={{ duration: 1.2, type: "spring", delay: 0.2 }}><span style={{ color: "white" }}>#</span> UNLEASH THE FUN</motion.div>
                    <motion.div viewport={{ once: true }} initial={{ transform: 'translateX(-6rem)', opacity: 0 }} whileInView={{ transform: 'translateX(0rem)', opacity: 1 }} transition={{ duration: 1.5, type: "spring" }}>Adipisicing ea deserunt laborum mollit.Qui laborum Lorem consequat sint magna reprehenderit reprehenderit sint ad aliqua occaecat irure quis.Exercitation consequat ea minim aliqua eiusmod anim adipisicing aliqua nulla voluptate ut mollit et cupidatat.</motion.div>
                </div>
                <div className={classes.proCardsWrap}>
                    {proNightsCard(pn_ss, "2ND MARCH - 7:00 PM", "SWATI SACHDEVA", "COMEDY", "Get ready for a night of belly laughs and endless giggles. Swati Sachdeva with her quick-witted sets and unique perspective is taking the stand-up comedy scene by storm. Whether she's poking fun at everyday situations or sharing hilarious anecdotes of her life, Swati's comedy is sure to have you in stitches.")}
                    {proNightsCard(pn_bp, "3RD MARCH - 7:00 PM", "BAND PARASHARA", "CONCERT", "Band PARASHARA are a progressive Delhi-based band. The rock atmospshere conceived by them is sure to leave everyone pumped up and asking for more. Using a great amount of flavors from multiple playing styles and genres, they'll surely take the night high with us.")}
                    {proNightsCard(pn_dc, "4TH MARCH - 7:00 PM", "DJ CARNIVORE", "EDM", "DJ Carnivore - an EDM artist - is ready to take OJAS to the next level! With his electrifying mixes and heart-pumping beats, he is guaranteed to have all your movements in sync to his beats. So, grab your friends and get ready to let loose as you experience the wild hymns of DJ Carnivore!")}
                    {proNightsCard(pn_ak, "5TH MARCH - 7:00 PM", "ASEES KAUR", "MUSIC", "Asees Kaur is famous all over India for her major hits like Raataan Lambiyan, Jaan Nisar, and Makhna to name a few. Whether she's belting out Bollywood hits or giving a new spin to classic songs, she'll leave you mesmerized. So, get ready to dance and sing as you experience her electrifying performance.")}
                </div>
        </section>*/}

            {/* <form className={classes.contact} id="contacts" onSubmit={sendMessage}>
                <div className={classes.contactBar} />
                <motion.div viewport={{ once: true }} initial={{ transform: 'translateX(6rem)', opacity: 0 }} whileInView={{ transform: 'translateX(0rem)', opacity: 1 }} transition={{ duration: 1.5, type: "spring" }} className={classes.contactHeader}>CONTACT US</motion.div>
                <div className={classes.textArea}><input name="subject" type="text" size="40" maxLength="200" required placeholder="Subject" /></div>
                <div className={classes.textArea}>
                    <textarea name="message" cols="40" rows="5" required placeholder="Write your message" />
                </div>
                <div className={classes.nameemail}>
                    <div className={classes.textArea}><input name="name" type="text" size="40" maxLength="150" required placeholder="Your name" /></div>
                    <div className={classes.textArea}><input name="email" type="email" size="40" maxLength="150" required placeholder="Your email" /></div>
                </div>
                {(contactBtnText === "SEND MESSAGE" || contactBtnText === "SENDING...") ? null : <div className={classes.contactAlert} style={{ borderLeft: contactBtnText === "MESSAGE SENT ✅" ? "0.4rem solid #00d26a" : "0.4rem solid #F7B95A" }}>{contactBtnText === "MESSAGE SENT ✅" ? "Your message has been sent." : "Something went wrong! Please send us an email instead to info@spjimr.org"}</div>}
                <motion.div viewport={{ once: true }} initial={{ transform: 'translateX(-6rem)', opacity: 0 }} whileInView={{ transform: 'translateX(0rem)', opacity: 1 }} transition={{ duration: 1.5, type: "spring" }}><button type="submit"><AngleButton text={contactBtnText} /></button></motion.div>
            </form> */}
        </div >
    );
}

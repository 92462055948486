import classes from "./index.module.css";
import { motion } from "framer-motion";
import AngleButton from "../angleButton";
import connectImg from "../../assets_pack/connect.webp";
import { HashLink } from "react-router-hash-link";

export default function Footer(props) {
    return (
        <>
            <section className={classes.contactsSection}>
                <motion.div viewport={{ once: true }} initial={{ transform: 'translateX(-10rem)', opacity: 0 }} whileInView={{ transform: 'translateX(0rem)', opacity: 1 }} transition={{ duration: 1.5, type: "spring", delay: 0.00 }}><img src={connectImg} alt="Connect" /></motion.div>
                <div>
                    <motion.div viewport={{ once: true }} initial={{ transform: 'translateY(4rem)', opacity: 0 }} whileInView={{ transform: 'translateY(0rem)', opacity: 1 }} transition={{ duration: 1.5, type: "spring", delay: 0.2 }} className={classes.socialContact}><a rel="noreferrer" target="_blank" href="mailto:info@spjimr.org">E-MAIL</a></motion.div>
                    <motion.div viewport={{ once: true }} initial={{ transform: 'translateY(4rem)', opacity: 0 }} whileInView={{ transform: 'translateY(0rem)', opacity: 1 }} transition={{ duration: 1.5, type: "spring", delay: 0.4 }} className={classes.socialContact}><a rel="noreferrer" target="_blank" href="https://www.instagram.com/spjimr.ojas">INSTAGRAM</a></motion.div>
                    <motion.div viewport={{ once: true }} initial={{ transform: 'translateY(4rem)', opacity: 0 }} whileInView={{ transform: 'translateY(0rem)', opacity: 1 }} transition={{ duration: 1.5, type: "spring", delay: 0.6 }} className={classes.socialContact}><a rel="noreferrer" target="_blank" href="https://youtube.com/@SPJIMRMumbai_Official?si=uijVy526YFiRd4S-">YOUTUBE</a></motion.div>
                    <motion.div viewport={{ once: true }} initial={{ transform: 'translateY(4rem)', opacity: 0 }} whileInView={{ transform: 'translateY(0rem)', opacity: 1 }} transition={{ duration: 1.5, type: "spring", delay: 0.8 }} className={classes.socialContact}><a rel="noreferrer" target="_blank" href="https://www.facebook.com/spjimr.ojas">FACEBOOK</a></motion.div>
                </div>
                <motion.div viewport={{ once: true }} initial={{ transform: 'translateX(10rem)', opacity: 0 }} whileInView={{ transform: 'translateX(0rem)', opacity: 1 }} transition={{ duration: 1.5, type: "spring", delay: 0.00 }}><a rel="noreferrer" target="_blank" href={"https://unstop.com/college-fests/ojas-xvi-sp-jain-institute-of-management-and-research-spjimr-mumbai-174210"}><AngleButton text="EXPLORE EVENTS" /></a></motion.div>
            </section>

            <section className={classes.footerSection}>
                <div><span style={{ color: "#3c3556" }}>© 2K24</span> OJAS XVI</div>
                <div style={{ color: "#3c3556" }}>SPJIMR, MUMBAI - 500082</div>
            </section>
        </>
    );
}